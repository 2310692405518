import * as Sentry        from '@sentry/browser';
import {
  sendBreadcrumbToSentry,
  breadcrumbData
}                         from './commonUsefulFunctions';

const parseJSON = response => response ? response.json() : {};

const vimcalFetch = (path, params, method, authorizationRequired=true) => {
  sendBreadcrumbToSentry({
    category: 'Fetch',
    message: path,
    data: {
      params: breadcrumbData(params),
      method,
      authorizationRequired,
    }
  });
  return fetch(path, constructParams(params, method, authorizationRequired));
}

const Fetcher = {
  get: (path, params={}, authorizationRequired=false) =>
    vimcalFetch(path, params, 'GET', authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        Sentry.captureException(err);
    }),
  post: (path, params={}, authorizationRequired=false) =>
    vimcalFetch(path, params, 'POST', authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        Sentry.captureException(err);
    }),
  patch: (path, params={}, authorizationRequired=false) =>
    vimcalFetch(path, params, 'PATCH', authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        Sentry.captureException(err);
    }),
  delete: (path, params={}, authorizationRequired=false) =>
    vimcalFetch(path, params, 'DELETE', authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        Sentry.captureException(err);
    }),
};

const constructParams = (params, verb, authorizationRequired) => {
  if (!params['headers']) {
    params['headers'] = {};
  }

  params['method'] = verb;
  params['headers']['Content-Type'] = 'application/json';

  let currentUser = null;

  if (authorizationRequired && currentUser) {
    params['headers']['X-VIMCAL-USER-EMAIL'] = currentUser;
    params['credentials'] = 'include';
  }

  return params;
};

export default Fetcher;
