import React, { Component }                 from 'react';
import * as Sentry                          from '@sentry/browser';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
